import Head from "next/head";
import Image from "next/image";
import React from "react";
import { styled } from "@stitches/react";

export default function Home() {
  return (
    <div>
      <Head>
        <title>Clausebook</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Container>
        <Image src="/logo.svg" width="252" height="63" alt="Clausebook" />
      </Container>
    </div>
  );
}

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
});
